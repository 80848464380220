body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rec-slider-container.rec.jNKBoY {
  margin: 0px 0px;
}

.rec-slider-container {
  margin: 0 !important;
  padding: 0 !important;
}

.rec-slider-container .rec-carousel-item {
  margin: 0 !important; /* Adjust this if necessary */
  padding: 0 !important; /* Adjust this if necessary */
}

.carousel-custom .rec-dot {
  display: flex; /* Flexbox for alignment */
  gap: 5px; /* Space between dots */
  background-color: #61ccf5;
  box-shadow: none;
}
.sc-gKsecS.IiDjp {
  background-color: #61ccf5; /* Changed to a blue color */
  box-shadow: none; /* Changed to match the new background color */
}
/*
@font-face {
  font-family: "Poppins";
  font-weight: normal;
  src: url("../public/assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Cairo";
  font-weight: normal;
  src: url("../public/assets/fonts/cairo_regular.ttf") format("truetype");
}
::-webkit-scrollbar{
    display: none
}*/

.loader {
  border: 8px solid "#1E184C";
}

.swiper-padding {
  padding: 0 40px; /* Default padding */
}

@media (max-width: 1200px) {
  .swiper-padding {
    padding: 0 20px; /* Adjust padding for smaller screens if needed */
  }
}

/* Style for all pagination bullets */

.custom-card {
  padding: 0 !important; /* Use !important to override default styles */
}

@import url("https://fonts.googleapis.com/css?family=Montserrat");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");

.custom-swiper .swiper-pagination-bullet {
  background: #61ccf5;

  width: 20px;

  height: 20px;

  border-radius: 50%;

  margin: 0 4px;

  border: 3px solid white; /* Added white border */
}

.custom-swiper .swiper-pagination-bullet-active {
  background: #61ccf5;

  width: 20px;

  height: 20px;

  border: "none";
}

.custom-prev-1,
.custom-next-1 {
  position: absolute;

  top: 50%;

  transform: translateY(-50%);

  z-index: 10;

  padding: 0 10px; /* Add padding to the left and right */
}

.custom-prev-1 {
  left: 5px; /* Adjust this value if needed */
}

.custom-next-1 {
  right: 5px; /* Adjust this value if needed */
}

/* Large screens */

@media (min-width: 1200px) {
  .custom-prev-1 img,
  .custom-next-1 img {
    width: 40px; /* Custom width for large screens */

    height: 40px; /* Custom height for large screens */
  }
}

/* Small screens */

@media (max-width: 1199px) and (min-width: 600px) {
  .custom-prev-1 img,
  .custom-next-1 img {
    width: 25px; /* Custom width for small screens */

    height: 25px; /* Custom height for small screens */
  }
}

/* Extra small screens */

@media (max-width: 599px) {
  .custom-prev-1 img,
  .custom-next-1 img {
    width: 20px; /* Custom width for extra small screens */

    height: 20px; /* Custom height for extra small screens */
  }
}

/** new*/
.custom-prev-2,
.custom-next-2 {
  position: absolute;

  top: 50%;

  transform: translateY(-50%);

  z-index: 10;
}

.custom-prev-2 {
  left: 5px; /* Adjust this value if needed */
}

.custom-next-2 {
  right: 5px; /* Adjust this value if needed */
}

/* Large screens */

@media (min-width: 1200px) {
  .custom-prev-2 img,
  .custom-next-2 img {
    width: 40px; /* Custom width for large screens */

    height: 40px; /* Custom height for large screens */
  }
}

/* Small screens */

@media (max-width: 1199px) and (min-width: 600px) {
  .custom-prev-2 img,
  .custom-next-2 img {
    width: 25px; /* Custom width for small screens */

    height: 25px; /* Custom height for small screens */
  }
}

/* Extra small screens */

@media (max-width: 599px) {
  .custom-prev-2 img,
  .custom-next-2 img {
    width: 20px; /* Custom width for extra small screens */

    height: 20px; /* Custom height for extra small screens */
  }
}

/** new*/
.custom-prev-4,
.custom-next-4 {
  position: absolute;

  top: 50%;

  transform: translateY(-50%);

  z-index: 10;
}

.custom-prev-4 {
  left: 5px; /* Adjust this value if needed */
}

.custom-next-4 {
  right: 5px; /* Adjust this value if needed */
}

/* Large screens */

@media (min-width: 1200px) {
  .custom-prev-4 img,
  .custom-next-4 img {
    width: 40px; /* Custom width for large screens */

    height: 40px; /* Custom height for large screens */
  }
}

/* Small screens */

@media (max-width: 1199px) and (min-width: 600px) {
  .custom-prev-4 img,
  .custom-next-4 img {
    width: 25px; /* Custom width for small screens */

    height: 25px; /* Custom height for small screens */
  }
}

/* Extra small screens */

@media (max-width: 599px) {
  .custom-prev-4 img,
  .custom-next-4 img {
    width: 20px; /* Custom width for extra small screens */

    height: 20px; /* Custom height for extra small screens */
  }
}

.swiper-slide {
  margin: 0 !important; /* Remove margin between slides if needed */
}
